.App {
  height: 100vh;
}

.title {
  background-color: #232e3a;
  color: #fff;
  height: 56px;
  line-height: 56px;
  padding-left: 16px;
  text-align: left;
}

.summary {
  height: 120px;
  line-height: 120px;
  background-color: #e8ecee;
  margin-bottom: 16px;
  text-align: center;
}

.details {
  display: grid-inline;
}
